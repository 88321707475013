<template>
    <div v-html="value">
    </div>
</template>

<script>
export default {
    name: 'QuickAdminHtml',
    props: {
        value: {
            required: true,
            type: String,
        },
    },
}
</script>
