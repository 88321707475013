<template>
    <div>
        <el-upload
            :action="uploadUrl"
            :before-upload="handleBeforeUpload"
            :on-success="handleUploadSuccess"
            :on-error="handleUploadError"
            name="file"
            :show-file-list="false"
            :headers="headers"
            style="display: none"
            ref="upload"
            v-if="this.type == 'url'"
        >
        </el-upload>
        <div class="editor" ref="editor" :style="styles"></div>
    </div>
</template>

<script>
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import '../../assets/css/quillEditor.css';
import { getToken } from '@/utils/auth'

// 引入字体
let fonts = [
    "SimSun",
    "SimHei",
    "Microsoft-YaHei",
    "KaiTi",
    "SongTi",
    "FangSong",
    "Arial",
    "Times-New-Roman",
    "sans-serif",
];

// 引入字号
let sizes = [false, "16px", "18px", "20px", "22px", "26px", "28px", "30px"];

export default {
    name: 'Editor',
    props: {
        /* 编辑器的内容 */
        value: {
            type: String,
            default: '',
        },
        /* 高度 */
        height: {
            type: Number,
            default: null,
        },
        /* 最小高度 */
        minHeight: {
            type: Number,
            default: 300,
        },
        /* 只读 */
        readOnly: {
            type: Boolean,
            default: false,
        },
        // 上传文件大小限制(MB)
        fileSize: {
            type: Number,
            default: 50,
        },
        /* 类型（base64格式、url格式） */
        type: {
            type: String,
            default: 'url',
        },
    },
    data() {
        return {
            uploadUrl: process.env.VUE_APP_BASE_API + '/admin/ajax/upload', // 上传的图片服务器地址
            headers: {
                token: getToken(),
            },
            Quill: null,
            currentValue: '',
            options: {
                theme: 'snow',
                bounds: document.body,
                debug: 'warn',
                modules: {
                    // 工具栏配置
                    toolbar: {
                        container: [
                            ['undo', 'redo', 'clean'], // 撤销 重做 清除文本格式
                            [{ font: fonts }], // 字号
                            [{ size: sizes }], // 字体大小
                            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                            // ['blockquote', 'code-block'], // 引用  代码块
                            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                            [{ indent: '-1' }, { indent: '+1' }], // 缩进
                            [{ align: [] }], // 对齐方式
                            ['link', 'image', 'video'], // 链接、图片、视频
                            [{ 'script': 'sub'}, { 'script': 'super' }], // 下标 上标
                        ],
                        handlers:{
                            'undo' : this.myUndo,
                            'redo' : this.myRedo,
                        },
                    }
                },
                placeholder: '请输入内容',
                readOnly: this.readOnly,
            },
        }
    },
    computed: {
        styles() {
            const style = {}
            if (this.minHeight) {
                style.minHeight = `${this.minHeight}px`
            }
            if (this.height) {
                style.height = `${this.height}px`
            }
            return style
        },
    },
    watch: {
        value: {
            handler(val) {
                if (val !== this.currentValue) {
                    this.currentValue = val === null ? '' : val
                    if (this.Quill) {
                        this.Quill.pasteHTML(this.currentValue)
                    }
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.init()
    },
    beforeDestroy() {
        this.Quill = null
    },
    methods: {
        init() {

            // 设置白名单
            let Font = Quill.import('formats/font');
            Font.whitelist = fonts;
            Quill.register(Font, true);
            
            let Size = Quill.import('formats/size');
            Size.whitelist = sizes;
            Quill.register(Size, true);
            
            // 添加撤销, 重做按钮
            let icons = Quill.import('ui/icons');
            icons["undo"] = `<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon><path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path></svg>`;
            icons["redo"] = `<svg viewbox="0 0 18 18"><polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon><path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path></svg>`;
            
            // 更改清除格式按钮
            icons["clean"] = `<svg xmlns="http://www.w3.org/2000/svg" style="width: 17px;" viewBox="0 0 21.14 18.97"><g><g><path d="M14.67,14.37v0L20.4,8.64a2.55,2.55,0,0,0,0-3.59L16.09.74a2.55,2.55,0,0,0-3.59,0L.74,12.49a2.56,2.56,0,0,0,0,3.6L3.5,18.84h0l0,0h0l0,0h0l0,0h0l0,0H16.9a.44.44,0,1,0,0-.88H11l3.7-3.71Zm-5,3.72H4L1.36,15.46a1.63,1.63,0,0,1-.48-1.17,1.65,1.65,0,0,1,.48-1.17L7.08,7.4l6.66,6.65ZM7.7,6.78l5.42-5.42A1.65,1.65,0,0,1,14.29.88a1.63,1.63,0,0,1,1.17.48l4.31,4.31a1.66,1.66,0,0,1,.49,1.17A1.64,1.64,0,0,1,19.77,8l-5.41,5.41Z"/></g></g></svg>`;
            const editor = this.$refs.editor;
            this.Quill = new Quill(editor, this.options)
            
            // 如果设置了上传地址则自定义图片上传事件
            if (this.type == 'url') {
                const toolbar = this.Quill.getModule('toolbar')
                toolbar.addHandler('image', value => {
                    this.uploadType = 'image'
                    if (value) {
                        this.$refs.upload.$children[0].$refs.input.click()
                    } else {
                        this.quill.format('image', false)
                    }
                })
            }
            this.Quill.pasteHTML(this.currentValue)
            this.Quill.on('text-change', (delta, oldDelta, source) => {
                const html = this.$refs.editor.children[0].innerHTML
                const text = this.Quill.getText()
                const quill = this.Quill
                this.currentValue = html
                this.$emit('input', html)
                this.$emit('on-change', { html, text, quill })
            })
            this.Quill.on('text-change', (delta, oldDelta, source) => {
                this.$emit('on-text-change', delta, oldDelta, source)
            })
            this.Quill.on('selection-change', (range, oldRange, source) => {
                this.$emit('on-selection-change', range, oldRange, source)
            })
            this.Quill.on('editor-change', (eventName, ...args) => {
                this.$emit('on-editor-change', eventName, ...args)
            })
        },
        // 上传前校检格式和大小
        handleBeforeUpload(file) {
            // 校检文件大小
            if (this.fileSize) {
                const isLt = file.size / 1024 / 1024 < this.fileSize
                if (!isLt) {
                    this.$message.error(`上传文件大小不能超过 ${this.fileSize} MB!`)
                    return false
                }
            }
            return true
        },
        handleUploadSuccess(res, file) {
            // 获取富文本组件实例
            const quill = this.Quill
            // 如果上传成功
            if (res.code == 1) {
                // 获取光标所在位置
                const length = quill.getSelection().index
                // 插入图片  res.data.url为服务器返回的图片地址
                quill.insertEmbed(length, 'image', res.data.url)
                // 调整光标到最后
                quill.setSelection(length + 1)
            } else {
                this.$message.error('图片插入失败')
            }
        },
        handleUploadError() {
            this.$message.error('图片插入失败')
        },
        myUndo(){
            this.Quill.history.undo();
        },
        myRedo(){
            this.Quill.history.redo();
        },
    },
}
</script>

<style>
.editor,
.ql-toolbar {
    white-space: pre-wrap !important;
    line-height: normal !important;
}
.quill-img {
    display: none;
}
.ql-snow .ql-tooltip[data-mode='link']::before {
    content: '请输入链接地址:';
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: '保存';
    padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode='video']::before {
    content: '请输入视频地址:';
}
.ql-snow .ql-tooltip[data-mode='video'],
.ql-snow .ql-tooltip[data-mode='link'] {
    left: 0 !important;
    top: 0 !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '14px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
    content: '10px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
    content: '18px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
    content: '32px';
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: '文本';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
    content: '标题1';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
    content: '标题2';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
    content: '标题3';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
    content: '标题4';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
    content: '标题5';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
    content: '标题6';
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: '标准字体';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
    content: '衬线字体';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
    content: '等宽字体';
}
</style>
