var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper" },
    [
      _c("navbar", { ref: "sendToNavbar" }),
      _c("app-main", { on: { sendToLayout: _vm.transition } }),
      _c(
        "div",
        { staticClass: "app-footer" },
        [
          _c("el-popover", { attrs: { trigger: "click" } }, [
            _c("img", {
              staticClass: "scan-code",
              attrs: { src: _vm.siteConfig.wx_code },
            }),
            _c(
              "span",
              {
                staticClass: "wechat",
                attrs: { slot: "reference" },
                slot: "reference",
              },
              [_c("img", { attrs: { src: _vm.wechat } }), _vm._v("WeChat ")]
            ),
          ]),
          _c("div", { staticStyle: { clear: "both" } }),
          _c("div", { staticClass: "copyright" }, [
            _vm._v(" " + _vm._s(_vm.siteConfig.site_title)),
            _c("br"),
            _vm._v(" " + _vm._s(_vm.siteConfig.site_copyright)),
            _c("br"),
            _c("br"),
            _vm._v(" " + _vm._s(_vm.siteConfig.site_beian) + " "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }