var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-file" },
    [
      _vm.fileList.length < _vm.limit
        ? _c(
            "el-upload",
            {
              ref: "upload",
              staticClass: "upload-file-uploader",
              attrs: {
                disabled: _vm.disabled,
                action: _vm.uploadFileUrl,
                "before-upload": _vm.handleBeforeUpload,
                "file-list": _vm.fileList,
                limit: _vm.limit,
                "on-error": _vm.handleUploadError,
                "on-exceed": _vm.handleExceed,
                "on-success": _vm.handleUploadSuccess,
                "show-file-list": false,
                headers: _vm.headers,
                drag: _vm.drag,
              },
            },
            [
              _vm.drag
                ? _c("i", { staticClass: "el-icon-plus upload-plus" })
                : _vm._e(),
              !_vm.drag
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        size: "mini",
                        type: "primary",
                        disabled: _vm.disabled,
                      },
                    },
                    [_vm._v("选取文件")]
                  )
                : _vm._e(),
              _vm.showTip
                ? _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [
                      _vm._v(" 请上传 "),
                      _vm.fileSize
                        ? [
                            _vm._v(" 大小不超过 "),
                            _c("b", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v(_vm._s(_vm.fileSize) + "MB"),
                            ]),
                          ]
                        : _vm._e(),
                      _vm.fileType
                        ? [
                            _vm._v(" 格式为 "),
                            _c("b", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v(_vm._s(_vm.fileType.join("/"))),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" 的文件 "),
                      _vm.drag
                        ? [
                            _c("br"),
                            _c("b", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v("点击按钮"),
                            ]),
                            _vm._v("或"),
                            _c("b", { staticStyle: { color: "#f56c6c" } }, [
                              _vm._v("拖拽文件"),
                            ]),
                            _vm._v("到本区域上传 "),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.isVideo
        ? [
            _vm._l(_vm.fileList, function (file, index) {
              return [
                _c(
                  "div",
                  { staticClass: "video-box" },
                  [
                    _c("el-button", {
                      attrs: {
                        type: "danger",
                        icon: "el-icon-close",
                        circle: "",
                        size: "mini",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleDelete(index)
                        },
                      },
                    }),
                    _c(
                      "video",
                      {
                        staticStyle: { width: "360px" },
                        attrs: { controls: "controls" },
                      },
                      [
                        _c("source", {
                          attrs: { src: file.url, type: "video/mp4" },
                        }),
                        _vm._v(" 您的浏览器暂不支持视频播放 "),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            }),
          ]
        : _c(
            "transition-group",
            {
              staticClass:
                "upload-file-list el-upload-list el-upload-list--text",
              attrs: { name: "el-fade-in-linear", tag: "ul" },
            },
            _vm._l(_vm.fileList, function (file, index) {
              return _c(
                "li",
                {
                  key: file.uid,
                  staticClass:
                    "el-upload-list__item ele-upload-list__item-content",
                },
                [
                  _c(
                    "el-link",
                    {
                      attrs: {
                        href: "" + _vm.baseUrl + file.url,
                        underline: false,
                        target: "_blank",
                      },
                    },
                    [
                      _c("span", { staticClass: "el-icon-document" }, [
                        _vm._v(" " + _vm._s(_vm.getFileName(file.name)) + " "),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "ele-upload-list__item-content-action" },
                    [
                      _c(
                        "el-link",
                        {
                          attrs: { underline: false, type: "danger" },
                          on: {
                            click: function ($event) {
                              return _vm.handleDelete(index)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }