<template>
    <div class="app-wrapper">
        <navbar ref="sendToNavbar" />
        <app-main @sendToLayout="transition" />
    
        <!-- FOOTER START -->
        <div class="app-footer">
            <el-popover trigger="click">
                <img :src="siteConfig.wx_code" class="scan-code">
                <span class="wechat" slot="reference">
                    <img :src="wechat">WeChat
                </span>
            </el-popover>
            <div style="clear: both;"></div>
            <div class="copyright">
                {{siteConfig.site_title}}<br>
                {{siteConfig.site_copyright}}<br><br>
                {{siteConfig.site_beian}}
            </div>
        </div>
        <!-- FOOTER END -->
    </div>
</template>

<script>
import { AppMain, Navbar } from './components'
import wechat from "@/assets/images/wxlogo.png";

export default {
    name: 'Layout',
    components: {
        AppMain,
        Navbar,
    },
    data() {
        return {
            wechat,
            siteConfig: {},
        }
    },
    created(){

        this.getSiteConfig().then(res => {
            this.$set(this, 'siteConfig', res);
        });
    },
    methods: {
        transition(params){
            this.$refs.sendToNavbar.doSth(params);
        }
    },
}
</script>

<style lang="scss" scoped>

.app-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    
    .occupy-space{
        height: 6.7vw;
    }
    
    .app-footer{
        width: 100%;
        height: 168px;
        bottom: -168px;
        background: #FFF;
        position: absolute;
        z-index: 999;
        border-top: 1px solid #999;
        
        .wechat{
            float: left;
            margin-left: 24px;
            margin-top: 14px;
            cursor: pointer;
            
            img{
                width: 26px;
                float: left;
                margin-right: 4px;
            }
        }
        
        .copyright{
            text-align: center;
            font-weight: bold;
            font-size: 14px;
            line-height: 22px;
        }
    }
}

.scan-code{
    width: 300px;
}
</style>
