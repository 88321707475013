var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("span", { ref: "span" }, [_vm._v(_vm._s(_vm.text))]),
      _c("el-input", {
        ref: "input",
        staticClass: "custom-input",
        on: {
          blur: function ($event) {
            return _vm.customChange($event, _vm.value)
          },
        },
        model: {
          value: _vm.text,
          callback: function ($$v) {
            _vm.text = $$v
          },
          expression: "text",
        },
      }),
      _c("i", {
        staticClass: "el-icon-edit my-icon",
        attrs: { id: "icon" },
        on: {
          click: function ($event) {
            return _vm.customChangePrep($event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }