<template>
    
    <div class="navbar" v-bind:style="{background:hasBg ? 'unset' : '#FFF'}">

        <router-link to="/" class="logo">
            <svg-icon icon-class="logo" />
        </router-link>
        
        <div class="right-tools">
            <svg @click="drawer('top')" class="icon-search" viewBox="0 0 19 20" :style="{fill: dark ? '#FFF' : '#000'}" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 8a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0zm-1.637 6.353a8 8 0 1 1 1.103-1.023l4.783 4.4-1.015 1.104-4.871-4.481z"></path>
            </svg>
            <span class="hamburger" @click="drawer('side')" :style="{borderColor: dark ? '#FFF' : '#000'}"></span>
        </div>

        <el-drawer
                :visible.sync="topDrawer"
                :show-close="false"
                :with-header="false"
                class="top-drawer"
                direction="ttb">
            <el-input v-model="content" align="center" placeholder="输入文字" @keyup.enter.native="goSearch">
                <svg slot="suffix" @click="goSearch" class="icon-search" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 8a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0zm-1.637 6.353a8 8 0 1 1 1.103-1.023l4.783 4.4-1.015 1.104-4.871-4.481z"></path>
                </svg>
            </el-input>
        </el-drawer>
    
        <el-drawer
                :visible.sync="sideDrawer"
                :show-close="false"
                :with-header="false"
                class="side-drawer"
                direction="rtl">
            <div class="menu-box">
                <div class="menu-li" v-for="item in menu">
                    <router-link :to="'/'+item.link">
                        <div class="menu-head">{{item.head}}</div>
                        <div class="menu-body" v-bind:class="{'menu-sp':item.sp}">{{item.body}}</div>
                    </router-link>
                </div>
            </div>
        </el-drawer>

    </div>
</template>

<script>

export default {
    data(){
        return {
            content: '',
            dark: false,
            saveDark: false,
            sideDrawer: false,
            topDrawer: false,
            menu: [
                {
                    head: 'exhibition',
                    link: 'exhibition',
                    body: '展览',
                    sp: true,
                },
                {
                    head: 'artist',
                    link: 'artist',
                    body: '艺术家',
                },
                {
                    head: 'academic',
                    link: 'study',
                    body: '研究',
                    sp: true,
                },
                {
                    head: 'video',
                    link: 'image',
                    body: '影像',
                    sp: true,
                },
                {
                    head: 'publication',
                    link: 'publication',
                    body: '出版物',
                },
                {
                    head: 'about',
                    link: 'about',
                    body: '关于',
                    sp: true,
                },
            ],
            hasBg: true,
        }
    },
    methods: {
        drawer(type){
            if( type=='top' ){
                this.sideDrawer = false;
                this.topDrawer = !this.topDrawer;
            } else {
                this.topDrawer = false;
                this.sideDrawer = !this.sideDrawer;
            }
        },
        goSearch(){
            this.$router.push({
                path: '/search',
                query: {
                    query: this.content,
                },
            });
        },
        doSth(params){
            if( !(this.sideDrawer || this.topDrawer) ){
                this.dark = params.dark;
            } else {
                this.saveDark = params.dark;
            }
        }
    },
    watch: {
        $route: {
            handler: function (route) {
                this.sideDrawer = this.topDrawer = false;
                this.hasBg = route.name=='Index' ? true : false;
                if( this.hasBg==false ){
                    this.dark = false;
                    this.saveDark = false;
                }

                /** 不显示工具栏 **/
                this.$nextTick(() => {
                    let tools = document.querySelector('.right-tools');
                    if( tools ){
                        tools.style.display = route.path=='/book' ? 'none' : 'unset';
                    }

                    // 除预约页面外, 手机版提示用电脑浏览
                    if( route.path!='/book' && route.path!='/about' ){
                        const mobileKeywords = ['android', 'iphone', 'ipod', 'ipad', 'windows phone'];
                        const userAgent = navigator.userAgent.toLowerCase();
                        this.msgSuccess(userAgent);
                        for (const keyword of mobileKeywords) {
                            if (userAgent.indexOf(keyword) !== -1) {
                                this.$alert(route.path);
                                if( route.path=='/index' ){
                                    this.$router.push('/book');
                                    return false;
                                }
                                this.$alert('请于电脑端打开', '敬告', {
                                    type: 'warning',
                                    customClass: 'mobile-alert',
                                    confirmButtonText: '确定',
                                    callback: () => {
                                        // this.$router.push('/about');
                                    },
                                });
                            }
                        }
                    }

                });
                
            },
            immediate: true,
        },
        topDrawer: {
            handler(nval) {
                if( nval==false ){
                    if( this.sideDrawer==false ){
                        this.dark = this.saveDark;
                    } else {
                        this.dark = false;
                    }
                } else {
                    this.saveDark = this.dark;
                    this.dark = false;
                }
            },
            immediate: true,
        },
        sideDrawer: {
            handler(nval) {
                if( nval==false ){
                    if( this.topDrawer==false ){
                        this.dark = this.saveDark;
                    } else {
                        this.dark = false;
                    }
                } else {
                    this.saveDark = this.dark;
                    this.dark = false;
                }
            },
            immediate: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.navbar {
    width: 100%;
    margin: 0 auto;
    padding: 20px 2.89vw 0;
    position: fixed;
    z-index: 3333;
    
    .logo {
        display: inline-block;
        margin-top: -10px;
        
        svg{
            width: 130px;
            height: 70px;
        }
    }
    
    .right-tools{
        float: right;
        
        .el-icon-search{
            font-size: 26px;
            cursor: pointer;
        }
        
        .icon-search{
            fill: #000;
            width: 22px;
            height: 22px;
            cursor: pointer;
        }
        
        .hamburger{
            width: 22px;
            height: 20px;
            float: right;
            border-top: 2px solid;
            border-bottom: 2px solid;
            margin-left: 40px;
            cursor: pointer;
        }
    }
    
    ::v-deep .top-drawer{
        z-index: -1!important;
        
        .el-drawer{
            height: 363px!important;
            padding: 0 2.89vw 0;
        }
    
        .el-input{
            
            font-size: 24px;
            margin-top: 230px;
            
            input{
                border-top: none;
                border-left: none;
                border-right: none;
                border-radius: 0;
                text-align: center;
                padding-bottom: 6px;
                letter-spacing: 2px;
            }
            
            .icon-search{
                width: 26px;
                height: 26px;
                fill: #000;
                cursor: pointer;
            }
            
            .el-input__suffix{
                top: 6px;
                right: 0;
                color: #000;
            }
        }
    }
    
    ::v-deep .side-drawer {
        z-index: -1 !important;
    
        .el-drawer{
            width: 350px!important;
            max-width: 500px;
            text-align: center;
            
            .menu-box{
                height: 90vh;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
            }
            .menu-li{
                width: fit-content;
                margin: 0 auto;
                cursor: pointer;
            }
            .menu-head{
                color: #999;
                font-size: 2vh;
                text-transform: uppercase;
            }
            .menu-body{
                font-size: 3vh;
                width: 120px;
                margin: 0 auto;
                letter-spacing: 6px;
            }
        }
    
        @media (max-height:700px) {
            .el-drawer{
                width: 300px!important;
    
                .menu-box{
                    margin-top: 6vh;
                }
            }
        }
    }
}
</style>

<style>

.mobile-alert{
    width: 100%;
    max-width: 420px;
}
</style>
