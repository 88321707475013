<template>
    <span class="component-mult-input">
    
        <el-row v-for="(item, index) in list">
            <input type="hidden" v-model="item.key" v-if="params.hideKey">
            <el-col :span="8" style="padding-right: 10px;" v-else>
                <el-input v-model="item.key" :placeholder="params.keyDesc"></el-input>
            </el-col>
            <el-col :span="10" style="padding-right: 10px;">
                <el-input v-model="item.val" :placeholder="params.valDesc" :disabled="params.valDisabled"></el-input>
            </el-col>
            <el-col :span="6">
                <el-button size="mini" type="error" plain icon="el-icon-delete" @click="delItem(item, index)"></el-button>
            </el-col>
        </el-row>
        
        <el-autocomplete
                v-model="search_title"
                :fetch-suggestions="querySearchAsync"
                :placeholder="params.placeholder"
                @select="handleSelect"
                @blur="handleBlur"
                @keyup.native="handleKeyup"
                v-if="params.url"
        ></el-autocomplete>
        
        <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addItem()" v-else></el-button>
        
    </span>
</template>

<script>

export default {
    name: 'MultInput',
    props: {
        value: [String, Object, Array],
        // 其他参数
        params: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            list: [{key:'',val:''}],
            search_title: '',
        }
    },
    watch: {
        value: {
            handler(nval) {
                // console.log(nval)
                if( nval ){
                    let arr = JSON.parse(nval);
                    this.list = arr ? arr : this.list;
                }
            },
            deep: true,
            immediate: true,
        },
        list: {
            handler(nval) {
                this.$emit('input', JSON.stringify(nval));
            },
            deep: true,
            immediate: true,
        },
        params: {
            handler(nval) {
                nval.keyDesc = nval.keyDesc ? nval.keyDesc : '键';
                nval.valDesc = nval.valDesc ? nval.valDesc : '值';
                nval.placeholder = nval.placeholder ? nval.placeholder : '请输入内容';
            },
            deep: true,
            immediate: true,
        },
        
    },
    methods: {
        addItem(){
            this.list.push({key:'',val:''});
        },
        delItem(item, index){

            this.$confirm('确认要删除?', '警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.list.splice(index, 1);
            });
        },
        querySearchAsync(queryString, cb) {
            
            this.request.post(this.params.url, { name: this.search_title }).then((res)=>{
                if( res.data ){
                    let results = [];
                    res.data.forEach(item => {
                        item.value = item.name ? item.name : item.title;
                        results.push(item);
                    });
                    cb(results);
                }
            });
        },
        handleSelect(item){
            
            // 判断是否已关联
            let check = true;
            this.list.forEach(one => {
                if( one.key==item.id && one.val==item.value ){
                    this.$message({
                        type: 'warning',
                        message: '已添加',
                    });
                    check = false;
                }
            });
            // 未关联, 关联上
            if( check ){
                
                this.list.forEach(one => {
                    if( check ){
                        if( one.key=='' && one.val=='' ){
                            one.key = item.id;
                            one.val = item.value;
                            check = false;
                        }
                    }
                });
                if( check ){
                    this.list.push({
                        key: item.id,
                        val: item.value,
                    });
                }
                
                this.$message({
                    type: 'success',
                    message: '添加成功',
                });
                
                this.search_title = '';
            }
        },
        handleBlur(){
            // this.$emit('sendToParent', this.search_title);
        },
        handleKeyup(event){
            let param = {
                keyCode: event.keyCode,
                content: this.search_title,
            }
            this.$emit('sendToParent', param);
        },
        // 父组件传参
        doSth(params){
            if( params.clear ){
                this.search_title = '';
            }
        },
    },
}
</script>
<style scoped lang="scss">
.el-row{
    margin-bottom: 10px;
}
</style>
