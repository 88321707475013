var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "component-baidu-map" }, [
    _c("div", { staticClass: "outer-box" }, [
      _c("div", { staticClass: "inner-cover" }),
      _c("div", { ref: "container", attrs: { id: "container" } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }